export const API_URL = "https://api.secondstage.app"
//export const API_URL = "http://localhost:8080"
//export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"
export const TOOLPRESS_API = "https://toolpress-api.2ndstage.app"

export const THEME_CONFIG = {
    mode:"dark",
    gradients: {
        default: ['#f2b549', '#f2b549'],
        home_question: ['#f2b549', '#f2b549'],
        applausometro: ['#f2b549', '#f2b549'],
        siNo:['#f2b549', '#f2b549'],
        sentiment: ['#f2b549', '#f2b549'],
        wordcloud: ['#f2b549', '#f2b549'],
        qna: ['#f2b549', '#f2b549'],
    },
    primary: '#f2b549',
    secondary: '#263238',
    accent: '#f2b549',
    text: '#ffffff',
    background:"#1c8c82",
    poll:{
        default:"#ffffff",
        answered:"#f2b549",
        correct: '#3A722D',
        wrong: '#DB0A16'
    }
}